@import './mix.less';
body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", Helvetica, sans-serif;
    min-width: 320px;
    position: relative;
    p {
        margin: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none;
    }
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            margin: 0;
        }
    }
    .call {
        z-index: 999;
        position: fixed;
        background: #008000;
        right: 55px;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        bottom: 55px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.46);
        .media( {
            right: 25px
        }
        , 571px);
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            line-height: 55px;
            .fa {
                font-size: 20px;
                color: #fff;
            }
        }
    }
    .container {
        max-width: 1040px;
        padding: 0 20px;
        margin: auto;
    }
    .box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 80px;
        .item {
            z-index: 2;
            text-align: center;
            margin-right: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .media( {
                margin-right: 0;
                width: 100%;
                margin-bottom: 30px;
            }
            , 571px);
            &:nth-child(2) {
                .media( {
                    margin-right: 0;
                }
                , 916px);
            }
            &:last-child {
                margin-right: 0;
                .media( {
                    width: 100%;
                    margin-top: 50px;
                }
                , 916px)
            }
            .image-item {
                height: 256px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }
            p {
                color: #fff;
                font-weight: 700;
                font-size: 19px;
            }
        }
    }
    .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
            text-align: center;
            background: transparent;
            border: 1px solid #FFDC4F;
            padding: 10px 0;
            margin-right: 10px;
            cursor: pointer;
            flex-basis: 200px;
            transition: background .3s;
            z-index: 3;
            font-size: 17px;
            color: #fff;
            font-weight: 700;
            &:first-child {
                .media( {
                    margin-right: 5px;
                    margin-left: 5px;
                    margin-bottom: 20px;
                }
                , 409px);
            }
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                background: #FFDC4F;
            }
        }
    }
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;
        h5 {
            z-index: 2;
            font-size: 30px;
            font-family: 'Open Sans', Helvetica, sans-serif;
            color: #fff;
            margin-bottom: 8px;
            font-weight: 400;
        }
        p {
            z-index: 2;
            text-transform: uppercase;
            font-size: 17px;
            padding: 3px 7px 3px 7px;
            color: #fff;
            border: 1px solid #FFDC4F;
        }
    }
    .menu-mobile {
        background: #454545;
        width: 240px;
        box-sizing: border-box;
        height: 100%;
        padding: 30px 20px 20px 20px;
        position: fixed;
        right: 100%;
        z-index: 999;
        display: block;
        overflow-y: auto;
        .close {
            display: flex;
            align-items: center;
            color: #fff;
            background: #373737;
            padding: 5px 7px;
            border-bottom-left-radius: 2px;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 300;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            p {
                margin-left: 5px;
            }
        }
        ul {
            li {
                margin-bottom: 7px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    font-weight: 700;
                    font-size: 15px;
                    color: #D6D6BF;
                }
            }
        }
    }
    .header {
        &.register {
            border-bottom: 2px solid #D9D9D9;
        }
        .container {
            z-index: 5;
            background: #fff;
            font-family: "PT Sans Caption", Helvetica, sans-serif;
            .top-menu {
                max-width: 1040px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                background: #fff;
                .media( {
                    max-width: 920px;
                }
                , 1081px);
                .media( {
                    max-width: 820px;
                }
                , 957px);
                .media( {
                    max-width: 620px;
                }
                , 848px);
                .fa-bars {
                    display: none;
                    font-size: 20px;
                    cursor: pointer;
                    .media( {
                        display: block;
                    }
                    , 848px);
                }
                .logo {
                    img {
                        width: 162px;
                        .media( {
                            width: 120px;
                        }
                        , 957px);
                    }
                }
                ul {
                    display: flex;
                    .media( {
                        display: none;
                    }
                    , 848px);
                    li {
                        font-weight: 700;
                        margin-right: 20px;
                        a {
                            color: #000;
                            &:hover {
                                color: #c10a00;
                            }
                        }
                    }
                }
            }
        }
        .main {
            padding: 40px 0;
            .bg('../img/bg3.png');
            position: relative;
            h5 {
                font-size: 40px;
            }
            .overlay {
                background: #000;
                opacity: .5;
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }
    .plans {
        padding: 30px 0 70px 0;
        background: #FFCD03;
        .title {
            h5 {
                color: #000;
            }
            p {
                color: #fff;
                border: 2px solid #fff;
            }
        }
        .buttons {
            a {
                margin-right: 0;
                border: 1px solid #fff;
                &:hover {
                    color: #000;
                    background: #fff;
                }
            }
        }
    }
    .payment {
        padding: 30px 0px 60px 0;
        .title {
            margin-bottom: 7px;
            h5 {
                color: #000;
            }
        }
        .buttons {
            a {
                background: #ffcd03;
            }
        }
        .profit {
            .wrap {
                margin: auto;
                max-width: 765px;
                .row {
                    display: flex;
                    padding: 0 10px;
                    margin-bottom: 20px;
                    .media( {
                        flex-wrap: wrap;
                        justify-content: center;
                    }
                    , 887px);
                    &.this {
                        h5 {
                            font-size: 20px;
                            text-align: center;
                            height: 100%;
                        }
                    }
                    h5 {
                        margin-top: 5px;
                        font-size: 20px;
                        font-weight: 600;
                        text-align: center;
                    }
                    .slider {
                        height: 25px;
                        margin-bottom: 10px;
                    }
                    .ui-slider-handle {
                        border-radius: 50%;
                        background: #FFCD00;
                        border: 1px solid #000;
                        width: 23px;
                        height: 23px;
                        top: -5px;
                    }
                    .left {
                        display: flex;
                        flex-direction: column;
                        flex-basis: 50%;
                        padding: 0 15px;
                        .media( {
                            flex-basis: 100%;
                            h5 {
                                margin-bottom: 20px;
                            }
                        }
                        , 887px);
                        input[type="number"] {
                            width: 100%;
                            border: 2px solid #eee;
                            padding: 5px 5px;
                            color: #9D8F8F;
                            font-size: 15px;
                        }
                        p {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            input {
                                font-size: 20px;
                                text-align: center;
                                width: 31px;
                                &:after {
                                    content: "часов";
                                }
                            }
                            span {
                                font-size: 20px;
                                color: #777;
                                font-weight: 600;
                            }
                        }
                    }
                    .right {
                        flex-basis: 50%;
                        display: flex;
                        flex-direction: column;
                        padding: 0 15px;
                        .media( {
                            flex-basis: 100%;
                            h5 {
                                margin-bottom: 20px;
                            }
                        }
                        , 887px);
                        select {
                            width: 100%;
                            border: 2px solid #eee;
                            padding: 5px 5px;
                            color: #9D8F8F;
                            font-size: 15px;
                        }
                        p {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            input {
                                font-size: 20px;
                                text-align: center;
                                width: 15px;
                                &:after {
                                    content: "часов";
                                }
                            }
                            span {
                                font-size: 20px;
                                color: #777;
                                font-weight: 600;
                            }
                        }
                    }
                }
                .money {
                    text-align: center;
                    margin-bottom: 40px;
                    h3 {
                        font-size: 35px;
                        font-weight: 600;
                        margin-bottom: 7px;
                        span {
                            color: #ff1f00;
                        }
                    }
                    h4 {
                        color: #777;
                        font-weight: 600;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .drive {
        padding: 30px 0px 60px 0;
        .title {
            h5 {
                text-align: center;
                color: #000;
            }
        }
        .box {
            h4 {
                opacity: .85;
                font-weight: 400;
                font-size: 20px;
            }
            p {
                margin-top: 5px;
                color: #000;
                font-weight: 200;
                opacity: .5;
                font-size: 15px;
            }
        }
        .buttons {
            a {
                text-align: center;
                background: #FFCD03;
            }
        }
    }
    .quetion {
        padding: 30px 0;
        .title {
            h5 {
                color: #000;
            }
        }
        .quet {
            margin-bottom: 30px;
            .que {
                padding: 0 10px;
                max-width: 745px;
                margin: auto;
                margin-bottom: 5px;
                &.active {
                    .item {
                        background: #FFCD03;
                        border: 1px solid #000;
                    }
                    .fa {
                        display: none;
                    }
                    .fa-minus {
                        display: block !important;
                    }
                }
                .item {
                    background: #F6F6F6;
                    padding: 10px 15px;
                    border: 1px solid #C5C5C5;
                    cursor: pointer;
                    &:hover {
                        background: #FFCD03;
                        border: 1px solid #000;
                    }
                    p {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        .fa {
                            margin-right: 5px;
                            font-size: 11px;
                            color: #444444;
                        }
                        .fa-minus {
                            display: none;
                            color: #fff;
                        }
                    }
                }
                .answer {
                    box-sizing: border-box;
                    display: none;
                    padding: 10px 25px;
                    font-size: 18px;
                    border: 1px solid #DDDDDD;
                    border-top: 0;
                    font-weight: 600;
                }
            }
        }
        .buttons {
            a {
                color: #fff;
                background: #FFCD03;
            }
        }
    }
    .advant {
        padding: 30px 0;
        .title {
            h5 {
                color: #000;
            }
        }
        .whatwe {
            .container {
                max-width: 1040px;
                margin: auto;
                display: flex;
                justify-content: center;
                padding: 0 25px;
                margin-bottom: 40px;
                .media( {
                    flex-wrap: wrap;
                }
                , 600px);
                .we {
                    margin-right: 15px;
                    flex-basis: 50%;
                    .media( {
                        flex-basis: 100%;
                        margin-bottom: 15px;
                    }
                    , 1030px);
                    .reason {
                        margin-bottom: 25px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        h4 {
                            font-weight: 600;
                            color: #777;
                            font-size: 18px;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            &::before {
                                margin-right: 10px;
                                font-weight: 100;
                                content: '\2714';
                                font-size: 30px;
                                color: #008000;
                            }
                        }
                        p {
                            font-size: 15px;
                            font-weight: 300;
                            opacity: .6;
                        }
                    }
                }
                .other {
                    flex-basis: 50%;
                    .media( {
                        flex-basis: 100%;
                    }
                    , 1030px);
                    .reason {
                        margin-bottom: 25px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        h4 {
                            font-weight: 600;
                            color: #777;
                            font-size: 18px;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            &::before {
                                margin-right: 10px;
                                font-weight: 100;
                                content: '\2718';
                                font-size: 30px;
                                color: #FF0000;
                            }
                        }
                        p {
                            font-size: 15px;
                            font-weight: 300;
                            opacity: .6;
                        }
                    }
                }
            }
            .buttons {
                a {
                    background: #FFCD03;
                }
            }
        }
    }
    .inf {
        padding: 30px 0;
        background: #FFCD03;
        .text {
            padding: 0 30px;
            margin-bottom: 30px;
            p {
                text-align: center;
                color: #000;
                opacity: .8;
            }
        }
        .buttons {
            a {
                border: 1px solid #fff;
                &:hover {
                    color: #000;
                    background: #fff;
                }
            }
        }
    }
    .footer {
        padding: 20px 0;
        .container {
            max-width: 1040px;
            margin: auto;
            padding: 0 20px;
            nav {
                padding-top: 10px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-top: 2px solid #D9D9D9;
                .o {
                    padding-right: 20px;
                    p {
                        font-size: 14px;
                        opacity: .75;
                    }
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        margin-right: 10px;
                        margin-bottom: 2px;
                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            color: #000;
                            font-weight: 600;
                            opacity: .8;
                            font-size: 15px;
                            &:hover {
                                color: #c10a00;
                            }
                        }
                    }
                }
            }
        }
    } // reg.html
    .reg {
        padding: 60px 0;
        .container {
            .title {
                h2 {
                    font-size: 33px;
                    opacity: .85;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                p {
                    border: none;
                    color: #000;
                    text-transform: unset;
                    font-size: 19px;
                    opacity: .65;
                    font-weight: 400;
                }
            }
            form {
                display: flex;
                flex-direction: column;
                button {
                    margin-top: 20px;
                    width: 120px;
                    background: #FFCD03;
                    font-size: 17px;
                    cursor: pointer;
                    border: 0;
                    padding: 7px 5px;
                    color: #fff;
                    font-weight: 700;
                    text-transform: capitalize;
                }
                .item {
                    max-width: 500px;
                    margin-bottom: 20px;
                    &.acept {
                        max-width: 100%;
                        position: relative;
                        margin-bottom: 40px;
                        .check {
                            padding-left: 0;
                            margin-top: 10px !important;
                            label {
                                font-weight: 400;
                                display: inline;
                            }
                        }
                    }
                    &.description {
                        max-width: 660px;
                    }
                    .top-m {
                        margin-top: 38px;
                        .media({
                            margin-top: 58px;
                        },405px);
                    }
                    .in {
                        &.rad {
                            position: relative;
                            #typecar-error {
                                position: absolute;
                                bottom: -25px;
                                left: 0;
                                .media({
                                    bottom: -47px;
                                },405px);
                            }
                        }
                    }
                    
                    #confirm-error {
                        position: absolute;
                        bottom: -25px;
                        left: 0;
                        .media({
                            bottom: -45px;
                        },405px);
                    }
                    .radio {
                        padding-left: 20px;
                        input {
                            display: inline-block;
                            height: auto;
                            width: auto;
                        }
                        label {
                            margin-top: 5px;
                        }
                    }
                    .check {
                        padding-left: 20px;
                        &:first-child {
                            margin-top: 50px;
                        }
                        input {
                            height: auto;
                            width: auto;
                            display: inline-block;
                        }
                        label {
                            display: inline-block;
                            margin-top: 0;
                        }
                    }
                    input {
                        display: block;
                        padding: 0 10px;
                        box-sizing: border-box;
                        height: 35px;
                        width: 100%;
                        border: 2px solid #eee;
                        margin-top: 5px;
                        font-size: 17px;
                        color: #8F8F8F;
                        &:focus {
                            border: 2px solid #FFDC4F;
                        }
                    }
                    .error {
                        margin-top: 7px;
                        font-size: 17px;
                        color: red;
                        font-weight: 400;
                    }
                    label {
                        opacity: .55;
                        font-weight: 700;
                        font-size: 15px;
                        flex-basis: 100%;
                        margin-top: 20px;
                        display: inline-block;
                    }
                    h5 {
                        opacity: .55;
                        font-weight: 700;
                        font-size: 15px;
                        margin-bottom: 5px;
                        margin: 20px 0;
                    }
                    textarea {
                        width: 100%;
                        resize: none;
                        height: 235px;
                    }
                }
            }
        }
    }
    .demands {
        .title {
            align-items: flex-start;
            h5 {
                margin-bottom: 20px;
                color: #000;
            }
        }
        .box {
            .item {
                .media( {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;
                }
                , 941px);
                .image-item {
                    height: auto;
                    width: auto;
                }
                h4 {
                    opacity: .85;
                    font-weight: 400;
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                    color: #000;
                    opacity: .8;
                    font-weight: 200;
                    margin-top: 5px;
                }
            }
        }
    } // contact.html 
    .contacts {
        padding: 30px 0;
        .title {
            box-sizing: border-box;
            align-items: flex-start;
            h5 {
                color: #000;
            }
        }
        .cont-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item {
                padding-right: 30px;
                &:nth-child(3) {
                    padding-right: 0;
                    .media( {
                        width: 100%;
                    }
                    , 589px);
                }
                &:nth-child(2) {
                    .media( {
                        padding-right: 0;
                        padding-left: 30px;
                    }
                    , 589px);
                    .media( {
                        padding-left: 0;
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    , 405px);
                }
                &:nth-child(1) {
                    .media( {
                        margin-bottom: 20px;
                    }
                    , 589px);
                }
                h5 {
                    opacity: .85;
                    font-weight: 400;
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                    color: #000;
                    opacity: .5;
                    font-weight: 200;
                    margin-top: 5px;
                }
            }
        }
    }
    .map {
        padding: 90px 0 30px 0;
        .title {
            h5 {
                color: #000;
            }
        }
    }
}