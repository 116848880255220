body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Helvetica, sans-serif;
  min-width: 320px;
  position: relative;
}
body p {
  margin: 0;
}
body h1,
body h2,
body h3,
body h4,
body h5 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none;
}
body ul {
  padding: 0;
  margin: 0;
}
body ul li {
  list-style: none;
  margin: 0;
}
body .call {
  z-index: 999;
  position: fixed;
  background: #008000;
  right: 55px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  bottom: 55px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.46);
}
@media (max-width: 571px) {
  body .call {
    right: 25px;
  }
}
body .call a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 55px;
}
body .call a .fa {
  font-size: 20px;
  color: #fff;
}
body .container {
  max-width: 1040px;
  padding: 0 20px;
  margin: auto;
}
body .box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
body .box .item {
  z-index: 2;
  text-align: center;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 571px) {
  body .box .item {
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}
@media (max-width: 916px) {
  body .box .item:nth-child(2) {
    margin-right: 0;
  }
}
body .box .item:last-child {
  margin-right: 0;
}
@media (max-width: 916px) {
  body .box .item:last-child {
    width: 100%;
    margin-top: 50px;
  }
}
body .box .item .image-item {
  height: 256px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
body .box .item p {
  color: #fff;
  font-weight: 700;
  font-size: 19px;
}
body .buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
body .buttons a {
  text-align: center;
  background: transparent;
  border: 1px solid #FFDC4F;
  padding: 10px 0;
  margin-right: 10px;
  cursor: pointer;
  flex-basis: 200px;
  transition: background 0.3s;
  z-index: 3;
  font-size: 17px;
  color: #fff;
  font-weight: 700;
}
@media (max-width: 409px) {
  body .buttons a:first-child {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 20px;
  }
}
body .buttons a:last-child {
  margin-right: 0;
}
body .buttons a:hover {
  background: #FFDC4F;
}
body .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
body .title h5 {
  z-index: 2;
  font-size: 30px;
  font-family: 'Open Sans', Helvetica, sans-serif;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 400;
}
body .title p {
  z-index: 2;
  text-transform: uppercase;
  font-size: 17px;
  padding: 3px 7px 3px 7px;
  color: #fff;
  border: 1px solid #FFDC4F;
}
body .menu-mobile {
  background: #454545;
  width: 240px;
  box-sizing: border-box;
  height: 100%;
  padding: 30px 20px 20px 20px;
  position: fixed;
  right: 100%;
  z-index: 999;
  display: block;
  overflow-y: auto;
}
body .menu-mobile .close {
  display: flex;
  align-items: center;
  color: #fff;
  background: #373737;
  padding: 5px 7px;
  border-bottom-left-radius: 2px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
body .menu-mobile .close p {
  margin-left: 5px;
}
body .menu-mobile ul li {
  margin-bottom: 7px;
}
body .menu-mobile ul li:last-child {
  margin-bottom: 0;
}
body .menu-mobile ul li a {
  font-weight: 700;
  font-size: 15px;
  color: #D6D6BF;
}
body .header.register {
  border-bottom: 2px solid #D9D9D9;
}
body .header .container {
  z-index: 5;
  background: #fff;
  font-family: "PT Sans Caption", Helvetica, sans-serif;
}
body .header .container .top-menu {
  max-width: 1040px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #fff;
}
@media (max-width: 1081px) {
  body .header .container .top-menu {
    max-width: 920px;
  }
}
@media (max-width: 957px) {
  body .header .container .top-menu {
    max-width: 820px;
  }
}
@media (max-width: 848px) {
  body .header .container .top-menu {
    max-width: 620px;
  }
}
body .header .container .top-menu .fa-bars {
  display: none;
  font-size: 20px;
  cursor: pointer;
}
@media (max-width: 848px) {
  body .header .container .top-menu .fa-bars {
    display: block;
  }
}
body .header .container .top-menu .logo img {
  width: 162px;
}
@media (max-width: 957px) {
  body .header .container .top-menu .logo img {
    width: 120px;
  }
}
body .header .container .top-menu ul {
  display: flex;
}
@media (max-width: 848px) {
  body .header .container .top-menu ul {
    display: none;
  }
}
body .header .container .top-menu ul li {
  font-weight: 700;
  margin-right: 20px;
}
body .header .container .top-menu ul li a {
  color: #000;
}
body .header .container .top-menu ul li a:hover {
  color: #c10a00;
}
body .header .main {
  padding: 40px 0;
  background: transparent url("../img/bg3.png") no-repeat center center;
  background-size: cover;
  position: relative;
}
body .header .main h5 {
  font-size: 40px;
}
body .header .main .overlay {
  background: #000;
  opacity: 0.5;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
body .plans {
  padding: 30px 0 70px 0;
  background: #FFCD03;
}
body .plans .title h5 {
  color: #000;
}
body .plans .title p {
  color: #fff;
  border: 2px solid #fff;
}
body .plans .buttons a {
  margin-right: 0;
  border: 1px solid #fff;
}
body .plans .buttons a:hover {
  color: #000;
  background: #fff;
}
body .payment {
  padding: 30px 0px 60px 0;
}
body .payment .title {
  margin-bottom: 7px;
}
body .payment .title h5 {
  color: #000;
}
body .payment .buttons a {
  background: #ffcd03;
}
body .payment .profit .wrap {
  margin: auto;
  max-width: 765px;
}
body .payment .profit .wrap .row {
  display: flex;
  padding: 0 10px;
  margin-bottom: 20px;
}
@media (max-width: 887px) {
  body .payment .profit .wrap .row {
    flex-wrap: wrap;
    justify-content: center;
  }
}
body .payment .profit .wrap .row.this h5 {
  font-size: 20px;
  text-align: center;
  height: 100%;
}
body .payment .profit .wrap .row h5 {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
body .payment .profit .wrap .row .slider {
  height: 25px;
  margin-bottom: 10px;
}
body .payment .profit .wrap .row .ui-slider-handle {
  border-radius: 50%;
  background: #FFCD00;
  border: 1px solid #000;
  width: 23px;
  height: 23px;
  top: -5px;
}
body .payment .profit .wrap .row .left {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 0 15px;
}
@media (max-width: 887px) {
  body .payment .profit .wrap .row .left {
    flex-basis: 100%;
  }
  body .payment .profit .wrap .row .left h5 {
    margin-bottom: 20px;
  }
}
body .payment .profit .wrap .row .left input[type="number"] {
  width: 100%;
  border: 2px solid #eee;
  padding: 5px 5px;
  color: #9D8F8F;
  font-size: 15px;
}
body .payment .profit .wrap .row .left p {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .payment .profit .wrap .row .left p input {
  font-size: 20px;
  text-align: center;
  width: 31px;
}
body .payment .profit .wrap .row .left p input:after {
  content: "часов";
}
body .payment .profit .wrap .row .left p span {
  font-size: 20px;
  color: #777;
  font-weight: 600;
}
body .payment .profit .wrap .row .right {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
@media (max-width: 887px) {
  body .payment .profit .wrap .row .right {
    flex-basis: 100%;
  }
  body .payment .profit .wrap .row .right h5 {
    margin-bottom: 20px;
  }
}
body .payment .profit .wrap .row .right select {
  width: 100%;
  border: 2px solid #eee;
  padding: 5px 5px;
  color: #9D8F8F;
  font-size: 15px;
}
body .payment .profit .wrap .row .right p {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .payment .profit .wrap .row .right p input {
  font-size: 20px;
  text-align: center;
  width: 15px;
}
body .payment .profit .wrap .row .right p input:after {
  content: "часов";
}
body .payment .profit .wrap .row .right p span {
  font-size: 20px;
  color: #777;
  font-weight: 600;
}
body .payment .profit .wrap .money {
  text-align: center;
  margin-bottom: 40px;
}
body .payment .profit .wrap .money h3 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 7px;
}
body .payment .profit .wrap .money h3 span {
  color: #ff1f00;
}
body .payment .profit .wrap .money h4 {
  color: #777;
  font-weight: 600;
  font-size: 18px;
}
body .drive {
  padding: 30px 0px 60px 0;
}
body .drive .title h5 {
  text-align: center;
  color: #000;
}
body .drive .box h4 {
  opacity: 0.85;
  font-weight: 400;
  font-size: 20px;
}
body .drive .box p {
  margin-top: 5px;
  color: #000;
  font-weight: 200;
  opacity: 0.5;
  font-size: 15px;
}
body .drive .buttons a {
  text-align: center;
  background: #FFCD03;
}
body .quetion {
  padding: 30px 0;
}
body .quetion .title h5 {
  color: #000;
}
body .quetion .quet {
  margin-bottom: 30px;
}
body .quetion .quet .que {
  padding: 0 10px;
  max-width: 745px;
  margin: auto;
  margin-bottom: 5px;
}
body .quetion .quet .que.active .item {
  background: #FFCD03;
  border: 1px solid #000;
}
body .quetion .quet .que.active .fa {
  display: none;
}
body .quetion .quet .que.active .fa-minus {
  display: block !important;
}
body .quetion .quet .que .item {
  background: #F6F6F6;
  padding: 10px 15px;
  border: 1px solid #C5C5C5;
  cursor: pointer;
}
body .quetion .quet .que .item:hover {
  background: #FFCD03;
  border: 1px solid #000;
}
body .quetion .quet .que .item p {
  display: flex;
  align-items: center;
  font-size: 18px;
}
body .quetion .quet .que .item p .fa {
  margin-right: 5px;
  font-size: 11px;
  color: #444444;
}
body .quetion .quet .que .item p .fa-minus {
  display: none;
  color: #fff;
}
body .quetion .quet .que .answer {
  box-sizing: border-box;
  display: none;
  padding: 10px 25px;
  font-size: 18px;
  border: 1px solid #DDDDDD;
  border-top: 0;
  font-weight: 600;
}
body .quetion .buttons a {
  color: #fff;
  background: #FFCD03;
}
body .advant {
  padding: 30px 0;
}
body .advant .title h5 {
  color: #000;
}
body .advant .whatwe .container {
  max-width: 1040px;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 0 25px;
  margin-bottom: 40px;
}
@media (max-width: 600px) {
  body .advant .whatwe .container {
    flex-wrap: wrap;
  }
}
body .advant .whatwe .container .we {
  margin-right: 15px;
  flex-basis: 50%;
}
@media (max-width: 1030px) {
  body .advant .whatwe .container .we {
    flex-basis: 100%;
    margin-bottom: 15px;
  }
}
body .advant .whatwe .container .we .reason {
  margin-bottom: 25px;
}
body .advant .whatwe .container .we .reason:last-child {
  margin-bottom: 0;
}
body .advant .whatwe .container .we .reason h4 {
  font-weight: 600;
  color: #777;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
body .advant .whatwe .container .we .reason h4::before {
  margin-right: 10px;
  font-weight: 100;
  content: '\2714';
  font-size: 30px;
  color: #008000;
}
body .advant .whatwe .container .we .reason p {
  font-size: 15px;
  font-weight: 300;
  opacity: 0.6;
}
body .advant .whatwe .container .other {
  flex-basis: 50%;
}
@media (max-width: 1030px) {
  body .advant .whatwe .container .other {
    flex-basis: 100%;
  }
}
body .advant .whatwe .container .other .reason {
  margin-bottom: 25px;
}
body .advant .whatwe .container .other .reason:last-child {
  margin-bottom: 0;
}
body .advant .whatwe .container .other .reason h4 {
  font-weight: 600;
  color: #777;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
body .advant .whatwe .container .other .reason h4::before {
  margin-right: 10px;
  font-weight: 100;
  content: '\2718';
  font-size: 30px;
  color: #FF0000;
}
body .advant .whatwe .container .other .reason p {
  font-size: 15px;
  font-weight: 300;
  opacity: 0.6;
}
body .advant .whatwe .buttons a {
  background: #FFCD03;
}
body .inf {
  padding: 30px 0;
  background: #FFCD03;
}
body .inf .text {
  padding: 0 30px;
  margin-bottom: 30px;
}
body .inf .text p {
  text-align: center;
  color: #000;
  opacity: 0.8;
}
body .inf .buttons a {
  border: 1px solid #fff;
}
body .inf .buttons a:hover {
  color: #000;
  background: #fff;
}
body .footer {
  padding: 20px 0;
}
body .footer .container {
  max-width: 1040px;
  margin: auto;
  padding: 0 20px;
}
body .footer .container nav {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #D9D9D9;
}
body .footer .container nav .o {
  padding-right: 20px;
}
body .footer .container nav .o p {
  font-size: 14px;
  opacity: 0.75;
}
body .footer .container nav ul {
  display: flex;
  flex-wrap: wrap;
}
body .footer .container nav ul li {
  margin-right: 10px;
  margin-bottom: 2px;
}
body .footer .container nav ul li:last-child {
  margin-right: 0;
}
body .footer .container nav ul li a {
  color: #000;
  font-weight: 600;
  opacity: 0.8;
  font-size: 15px;
}
body .footer .container nav ul li a:hover {
  color: #c10a00;
}
body .reg {
  padding: 60px 0;
}
body .reg .container .title h2 {
  font-size: 33px;
  opacity: 0.85;
  font-weight: 400;
  margin-bottom: 10px;
}
body .reg .container .title p {
  border: none;
  color: #000;
  text-transform: unset;
  font-size: 19px;
  opacity: 0.65;
  font-weight: 400;
}
body .reg .container form {
  display: flex;
  flex-direction: column;
}
body .reg .container form button {
  margin-top: 20px;
  width: 120px;
  background: #FFCD03;
  font-size: 17px;
  cursor: pointer;
  border: 0;
  padding: 7px 5px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
}
body .reg .container form .item {
  max-width: 500px;
  margin-bottom: 20px;
}
body .reg .container form .item.acept {
  max-width: 100%;
  position: relative;
  margin-bottom: 40px;
}
body .reg .container form .item.acept .check {
  padding-left: 0;
  margin-top: 10px !important;
}
body .reg .container form .item.acept .check label {
  font-weight: 400;
  display: inline;
}
body .reg .container form .item.description {
  max-width: 660px;
}
body .reg .container form .item .top-m {
  margin-top: 38px;
}
@media (max-width: 405px) {
  body .reg .container form .item .top-m {
    margin-top: 58px;
  }
}
body .reg .container form .item .in.rad {
  position: relative;
}
body .reg .container form .item .in.rad #typecar-error {
  position: absolute;
  bottom: -25px;
  left: 0;
}
@media (max-width: 405px) {
  body .reg .container form .item .in.rad #typecar-error {
    bottom: -47px;
  }
}
body .reg .container form .item #confirm-error {
  position: absolute;
  bottom: -25px;
  left: 0;
}
@media (max-width: 405px) {
  body .reg .container form .item #confirm-error {
    bottom: -45px;
  }
}
body .reg .container form .item .radio {
  padding-left: 20px;
}
body .reg .container form .item .radio input {
  display: inline-block;
  height: auto;
  width: auto;
}
body .reg .container form .item .radio label {
  margin-top: 5px;
}
body .reg .container form .item .check {
  padding-left: 20px;
}
body .reg .container form .item .check:first-child {
  margin-top: 50px;
}
body .reg .container form .item .check input {
  height: auto;
  width: auto;
  display: inline-block;
}
body .reg .container form .item .check label {
  display: inline-block;
  margin-top: 0;
}
body .reg .container form .item input {
  display: block;
  padding: 0 10px;
  box-sizing: border-box;
  height: 35px;
  width: 100%;
  border: 2px solid #eee;
  margin-top: 5px;
  font-size: 17px;
  color: #8F8F8F;
}
body .reg .container form .item input:focus {
  border: 2px solid #FFDC4F;
}
body .reg .container form .item .error {
  margin-top: 7px;
  font-size: 17px;
  color: red;
  font-weight: 400;
}
body .reg .container form .item label {
  opacity: 0.55;
  font-weight: 700;
  font-size: 15px;
  flex-basis: 100%;
  margin-top: 20px;
  display: inline-block;
}
body .reg .container form .item h5 {
  opacity: 0.55;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 5px;
  margin: 20px 0;
}
body .reg .container form .item textarea {
  width: 100%;
  resize: none;
  height: 235px;
}
body .demands .title {
  align-items: flex-start;
}
body .demands .title h5 {
  margin-bottom: 20px;
  color: #000;
}
@media (max-width: 941px) {
  body .demands .box .item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}
body .demands .box .item .image-item {
  height: auto;
  width: auto;
}
body .demands .box .item h4 {
  opacity: 0.85;
  font-weight: 400;
  font-size: 20px;
}
body .demands .box .item p {
  font-size: 15px;
  color: #000;
  opacity: 0.8;
  font-weight: 200;
  margin-top: 5px;
}
body .contacts {
  padding: 30px 0;
}
body .contacts .title {
  box-sizing: border-box;
  align-items: flex-start;
}
body .contacts .title h5 {
  color: #000;
}
body .contacts .cont-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
body .contacts .cont-box .item {
  padding-right: 30px;
}
body .contacts .cont-box .item:nth-child(3) {
  padding-right: 0;
}
@media (max-width: 589px) {
  body .contacts .cont-box .item:nth-child(3) {
    width: 100%;
  }
}
@media (max-width: 589px) {
  body .contacts .cont-box .item:nth-child(2) {
    padding-right: 0;
    padding-left: 30px;
  }
}
@media (max-width: 405px) {
  body .contacts .cont-box .item:nth-child(2) {
    padding-left: 0;
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 589px) {
  body .contacts .cont-box .item:nth-child(1) {
    margin-bottom: 20px;
  }
}
body .contacts .cont-box .item h5 {
  opacity: 0.85;
  font-weight: 400;
  font-size: 20px;
}
body .contacts .cont-box .item p {
  font-size: 15px;
  color: #000;
  opacity: 0.5;
  font-weight: 200;
  margin-top: 5px;
}
body .map {
  padding: 90px 0 30px 0;
}
body .map .title h5 {
  color: #000;
}
